import { Flex, FlexProps, Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow/chevron_right.svg';
import { CSSProperties } from 'react';

import * as S from './TitleTabWithLink.styled';

export const DEFAULT_TITLE_TAB_HEIGHT="60px";

interface TitleTabProps extends Omit<FlexProps, 'textStyle'> {
  /** 페이지 타이틀 */
  title?: string;
  /** 페이지 서브 타이틀 */ 
  subTitle?: JSX.Element;
  /** 우측에 노출될 버튼 */
  buttons?: JSX.Element[];
  /* 보더 스타일 */
  border?: string;
  /** 아이콘 컴포넌트 */
  icon?: JSX.Element;
  /** 이전 페이지 타이틀 */
  historyLinkTitle?: string;
  /** 이전 페이지 링크 */
  historyLink?: string;
  /** 이전 페이지 링크 클릭 시 동작 함수 */
  onClickHistoryLink?: () => void;
  /** 타이틀 스타일 */
  titleStyle?: CSSProperties;
}

const TitleTabWithLink = ({
  title,
  titleStyle,
  subTitle,
  buttons,
  icon,
  border = '0 0 1px 0',
  historyLink = '',
  historyLinkTitle= '',
  onClickHistoryLink,
  ...props
}: TitleTabProps) => {
  const router = useRouter();

  return (
    <Flex
      w="100%"
      minH={DEFAULT_TITLE_TAB_HEIGHT}
      px="20px"
      alignItems="center"
      justifyContent="space-between"
      borderWidth={border}
      borderColor="var(--N40)"
      {...props}
      >
      <Flex alignItems="center" gap="8px" flexShrink={0}>
        {
          historyLinkTitle && (
            <>
              <S.HistoryTitle onClick={() => {
                if(typeof onClickHistoryLink === 'function'){
                  onClickHistoryLink();
                  return;
                }

                if(historyLink){
                  router.push(historyLink);
                }
              }}>
                {historyLinkTitle}
              </S.HistoryTitle>
              <ArrowRight width={20} height={20} fill="var(--N60)"/>
            </>
          )
        }

        <S.Title style={titleStyle}>
          {title}
        </S.Title>
        {icon && <Flex>{icon}</Flex>}
      </Flex>
      <Flex alignItems="center">
        {subTitle && <Flex mr="12px">{subTitle}</Flex>}
        {buttons && <Flex gap="10px">{buttons?.map((item) => item)}</Flex>}
      </Flex>
    </Flex>
  );
};

export default TitleTabWithLink;
