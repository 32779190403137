import { Menus } from './types';

const menus: Menus = {
  home: {
    label: '홈',
    path: '/',
    tabs: [],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  payee_mgt: {
    label: '수급자 관리',
    path: '/payee_mgt',
    tabs: [
      { label: '수급자 정보관리' },
      { label: '전체 기초평가 현황' },
      { label: '기초평가 관리' },
      { label: '상태변화 기록지 작성' },
      { label: '상담일지' },
      { label: '수급자 안전관리 자료제공' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  schedule_mgt: {
    label: '일정관리',
    path: '/schedule_mgt',
    tabs: [
      { label: '수급자 일정관리' },
      { label: '요양보호사 근무 일정' },
      { label: '보호사 일정 한눈에 보기' },
      { label: '복지사 담당수급자 지정' },
      { label: '복지사 라운딩 일정' },
      { label: '라운딩 일정 한눈에 보기' },
      { label: '복지사 업무수행일지 작성' },
      // { label: '복지사 업무수행일지 현황' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },

  salary_supply: {
    label: '급여제공',
    path: '/salary_supply',
    tabs: [
      { label: '청구내역상세' },
      { label: '급여제공기록 RFID' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  staff_mgt: {
    label: '직원관리',
    path: '/staff_mgt',
    tabs: [
      { label: '직원 정보관리' },
      { label: '근무일지 및 출근부 관리' },
      { label: '고충처리 관리' },
      { label: '수급자 사례회의록' },
      { label: '직원회의록 (회의, 교육)' },
      { label: '건강검진관리' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  staff_salary: {
    label: '직원 월 급여',
    path: '/staff_salary',
    tabs: [
      { label: '월 급여대장(급여명세서)' },
      { label: '직원 월 급여 기초 설정' },
      { label: '시설 월 급여 기초 설정 (수당/공제)' },
      { label: '퇴직적립금 관리' },
      // { label: '인건비 지출비율 관리' },
      { label: '직원 연간급여대장' },
    ],
    allowed: {
      userType: ['admin'],
    },
  },
  charge: {
    label: '본인부담금',
    path: '/charge',
    tabs: [
      { label: '본인부담금 청구관리' },
      { label: '본인부담금 입금관리' },
      { label: '본인부담금 자동이체(CMS)' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  operation_mgt: {
    label: '운영관리',
    path: '/operation_mgt',
    tabs: [
      { label: '기관정보 설정' },
      { label: '메뉴 권한 설정' },
      { label: '차량/욕조/비품관리' },
      { label: '배상책임보험 가입현황' },
    ],
    allowed: {
      userType: ['admin'],
    },
  },

  service: {
    label: '부가서비스',
    path: '/service',
    tabs: [
      { label: '서식자료실' },
      { label: '업무 가이드' },
      { label: '기존 자료' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
};

export default menus;
