import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

export const SelectorContainer = styled(Box)`
  position: relative;
`;

export const SelectorButton = styled.button`
  display: flex;
  column-gap: 4px;
  align-items: center;
  max-width: 288px;
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const SelectorButtonArrow = styled(Box)`
  transform: rotate(90deg);
`;

export const SelectorDropdownContainer = styled(Box)`
  z-index: 99;
  position: absolute;
  top: 45px;
  right: 0;
  padding: 8px;
  background: var(--white);
  border: 1px solid var(--N40);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, var(--TD015));

  &[data-hidden="true"] {
    display: none;
  }
`;

export const SelectorDropdownButton = styled.button`
  overflow: hidden;
  width: max-content;
  min-width: 100%;
  max-width: 274px;
  padding: 8px;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: -0.28px;
  text-align: left;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--TD004);
  }
`;
