import { useMemo } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { Props as ModalProps } from '@components/v2/Modal';

import IconXClose from '@kidsnote/icons/kds/XClose';

import TitleTabWithLink from '../../TitleTabWithLink';
import * as S from './SitemapModal.styled';

import useMenus, { Menu } from 'hooks/v2/useMenus';
import useRouterPush from 'hooks/v2/useRouterPush';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'>;

export default function SitemapModal({ isOpen, onClose }: Props) {
  const baseMenus = useMenus();
  const { routerPush } = useRouterPush();

  const menus: (Menu | null)[] = useMemo(() => {
    const excludePaths = ['/']; // sitemap에서 "홈" 메뉴는 노출하지 않는다
    const nullCount =
      S.COLUMN_COUNT -
      ((Object.keys(baseMenus).length - excludePaths.length) % S.COLUMN_COUNT ||
        S.COLUMN_COUNT);
    return Object.values(baseMenus)
      .filter(({ path }) => !excludePaths.includes(path))
      .concat(new Array(nullCount).fill(null));
  }, [baseMenus]);

  return (
    <S.SitemapContainer aria-hidden={!isOpen}>
      <TitleTabWithLink
        title="전체메뉴"
        buttons={[
          <button key="sitemap-close" type="button" onClick={onClose}>
            <IconXClose size={24} accessibilityLabel="sitemap-close" />
          </button>,
        ]}
      />
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{ height: 'calc(100% - 60px)' }}
      >
        <S.MenuContainer>
          {menus.map((menu, menuIndex) => (
            <S.MenuWrapper key={menuIndex}>
              {menu && (
                <>
                  <S.MenuLabel>{menu.label}</S.MenuLabel>

                  <S.TabButtonContainer>
                    {menu.tabs.map((tab, tabIndex) => (
                      <S.TabButton
                        key={tab.label}
                        type="button"
                        onClick={async () => {
                          await routerPush({
                            pathname: menu.path,
                            query: { tab: tabIndex },
                          });
                          onClose();
                        }}
                      >
                        {tab.label}
                      </S.TabButton>
                    ))}
                  </S.TabButtonContainer>
                </>
              )}
            </S.MenuWrapper>
          ))}
        </S.MenuContainer>
      </OverlayScrollbarsComponent>
    </S.SitemapContainer>
  );
}
