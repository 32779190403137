import { useEffect, useState } from 'react';

import IconDotsGrid from '@kidsnote/icons/kds/DotsGrid';

import SitemapModal from './SitemapModal';

export default function SitemapButton() {
  const [isOpenSitemapModal, setIsOpenSitemapModal] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = isOpenSitemapModal ? 'hidden' : 'auto';
  }, [isOpenSitemapModal]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpenSitemapModal(!isOpenSitemapModal)}
      >
        <IconDotsGrid
          size={24}
          color="var(--white)"
          accessibilityLabel="sitemap"
        />
      </button>

      <SitemapModal
        isOpen={isOpenSitemapModal}
        onClose={() => setIsOpenSitemapModal(false)}
      />
    </>
  );
}
