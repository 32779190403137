import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';

export const GnbContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 10px 20px;
  background: var(--G600);
`;

export const LogoWrapper = styled(Flex)`
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
`;

export const UtilContainer = styled(Flex)`
  align-items: center;
  column-gap: 24px;
`;

export const UtilButtonContainer = styled(Flex)`
  align-items: center;
  column-gap: 4px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: var(--white);
  cursor: pointer;
`;
