import { useMutation, useQuery } from 'react-query';

import instance from '@apis/_axios/instance';

import {
  EmployeePermissionRequestType,
  EmployeePermissionType,
} from '../@types/data-contracts';
import {
  MutationHookParams,
  QueryHookParams,
} from '../@types/react-query-type';
import { ContentType, HttpClient, RequestParams } from '../http-client';

export class EmployeePermissionApi extends HttpClient {
  /**
   * No description
   *
   * @tags employee_permission
   * @name EmployeePermissionMeRetrieve
   * @summary 직원 권한 상세 조회 O
   * @request GET:/v1/center/{center_id}/employee_permission/
   */
  employeePermissionMeRetrieve = async (variables: {
    centerId: number;
    params?: RequestParams;
  }) => {
    const { data } = await this.request<EmployeePermissionType, any>({
      path: `/v1/center/${variables.centerId}/employee_permission/`,
      method: 'GET',
      format: 'json',
      ...variables.params,
    });

    return data;
  };
  /**
   * No description
   *
   * @tags employee_permission
   * @name EmployeePermissionUpdate
   * @summary 직원 권한 수정 O
   * @request PUT:/v1/center/{center_id}/employee_permission/
   */
  employeePermissionUpdate = async (variables: {
    centerId: number;
    data: EmployeePermissionRequestType;
    params?: RequestParams;
  }) => {
    const { data } = await this.request<EmployeePermissionType, any>({
      path: `/v1/center/${variables.centerId}/employee_permission/`,
      method: 'PUT',
      body: variables.data,
      type: ContentType.Json,
      format: 'json',
      ...variables.params,
    });

    return data;
  };
}

export const employeePermissionApi = new EmployeePermissionApi({ instance });

// ========================HOOKS========================

/**
 * QUERY_KEYS
 */
export const QUERY_KEY_EMPLOYEE_PERMISSION_API = {
  ME_RETRIEVE: (
    variables: Parameter<
      typeof employeePermissionApi.employeePermissionMeRetrieve
    >,
  ) => ['EMPLOYEE_PERMISSION_ME_RETRIEVE', variables],
  UPDATE: () => ['EMPLOYEE_PERMISSION_UPDATE'],
} as const;

/**
 * No description
 *
 * @tags employee_permission
 * @name EmployeePermissionMeRetrieve
 * @summary 직원 권한 상세 조회 O
 * @request GET:/v1/center/{center_id}/employee_permission/
 */
export const useEmployeePermissionMeRetrieveQuery = (
  params: QueryHookParams<
    typeof employeePermissionApi.employeePermissionMeRetrieve
  >,
) => {
  const queryKey = QUERY_KEY_EMPLOYEE_PERMISSION_API.ME_RETRIEVE(
    params.variables,
  );
  const result = useQuery(
    queryKey,
    () => employeePermissionApi.employeePermissionMeRetrieve(params.variables),
    params?.options,
  );

  return { ...result, queryKey };
};

/**
 * No description
 *
 * @tags employee_permission
 * @name EmployeePermissionUpdate
 * @summary 직원 권한 수정 O
 * @request PUT:/v1/center/{center_id}/employee_permission/
 */
export const useEmployeePermissionUpdateMutation = (
  params: MutationHookParams<
    typeof employeePermissionApi.employeePermissionUpdate
  >,
) => {
  const mutationKey = QUERY_KEY_EMPLOYEE_PERMISSION_API.UPDATE();
  const result = useMutation(
    mutationKey,
    employeePermissionApi.employeePermissionUpdate,
    params?.options,
  );

  return { ...result, mutationKey };
};
