import { useEffect, useLayoutEffect, useState } from 'react';
import Image from 'next/image';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { Accordion, Flex, Text } from '@chakra-ui/react';

import CsBanner from './CsBanner';
import Menu from './Menu';
import ShortcutBanner from './ShortcutBanner';

import { URLS } from 'constants/urls';
import useMenus from 'hooks/v2/useMenus';
import useRouterPush from 'hooks/v2/useRouterPush';
import dcMenus from 'hooks/v2/useMenus/dcMenus'
import { useAlert } from 'provider/store';
import preparingMenu from 'assets/images/preparing/menu.3x.png';

export default function LNB() {
  const menus = useMenus();
  const { router, routerPush } = useRouterPush();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);
  const [reduceMotion, setReduceMotion] = useState(true);
  const setAlert = useAlert((store) => store.setAlert);

  // 아코디언이 열린 상태로 페이지 이동 시 아코디언이 닫혔다가 다시 열리는 현상 방지
  useEffect(() => {
    setReduceMotion(false);
  }, []);

  useLayoutEffect(() => {
    const currentMenu = router.pathname.split('/')[1];
    const menuIndex = Object.values(menus).findIndex(
      (menu) => menu.path === `/${currentMenu}`,
    );
    setSelectedMenuIndex(menuIndex < 0 ? 0 : menuIndex);
  }, [router.pathname, menus]);

  const handleToggleAccordion = (path: string, index: number) => {
    // NOTE 베타 버전에서 급여(서비스) 제공관리 미노출
    if (path === dcMenus.health_care_benefit.path) {
      setAlert({
        title: '서비스 준비중입니다.',
        children: (
          <>
            <Text marginBottom="24px">
              빠른 시일 내 이용하실 수 있도록 열심히 노력 중입니다.
              <br />
              조금만 기다려주세요.
            </Text>
            <Image
              src={preparingMenu}
              unoptimized
              width={472}
              height={150}
            />
          </>
        ),
      });
      return;
    }

    setSelectedMenuIndex(index === selectedMenuIndex ? -1 : index);

    if (path === '/') {
      routerPush({
        pathname: '/',
        query: { tab: undefined },
      });
    }
  };

  return (
    <Flex
      flexDirection="column"
      position="relative"
      zIndex="99"
      width="240px"
      padding="12px 0"
      backgroundColor="var(--white)"
      borderRight="1px solid var(--N40)"
    >
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: 'move' } }}
        style={{ height: '100%' }}
      >
        <Accordion
          allowToggle
          index={selectedMenuIndex}
          reduceMotion={reduceMotion}
        >
          {Object.values(menus).map((menu, index) => (
            <Menu
              key={menu.path}
              menu={menu}
              onToggleAccordion={() => handleToggleAccordion(menu.path, index)}
            />
          ))}
        </Accordion>
      </OverlayScrollbarsComponent>

      <ShortcutBanner
        fontWeight={500}
        text="원격지원 바로가기"
        link={URLS.RECIPIENTS_STATUS_REMOTE_SUPPORT}
      />

      <ShortcutBanner
        fontWeight={700}
        color="var(--G600)"
        text="패밀리노트 바로가기"
        link={`${process.env.NEXT_PUBLIC_FN_WEB_MAIN_HOST}/service`}
      />
      <CsBanner />
    </Flex>
  );
}
