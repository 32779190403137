import { Menus } from './types';

const menus: Menus = {
  home: {
    label: '홈',
    path: '/',
    tabs: [],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  payee_mgt: {
    label: '수급자관리',
    path: '/payee_mgt',
    tabs: [{ label: '수급자 정보관리' }],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  schedule_vehicle_mgt: {
    label: '일정/차량관리',
    path: '/schedule_vehicle_mgt',
    tabs: [
      { label: '차량관리' },
      { label: '업무일정관리' },
    ],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  health_care_benefit: {
    label: '급여(서비스) 제공관리',
    path: '/health_care_benefit',
    // NOTE 베타 버전 미노출
    // tabs: [{ label: '요양급여 제공 기록' }],
    tabs: [],
    allowed: {
      userType: ['admin', 'teacher'],
    },
  },
  staff_mgt: {
    label: '직원관리',
    path: '/staff_mgt',
    tabs: [{ label: '직원 정보관리' }],
    allowed: {
      userType: ['admin'],
    },
  },
  operation_mgt: {
    label: '운영관리',
    path: '/operation_mgt',
    tabs: [{ label: '기관정보 설정' }],
    allowed: {
      userType: ['admin'],
    },
  },

};

export default menus;
