import Link from 'next/link';
import { Flex, FlexProps } from '@chakra-ui/react';
import IconArrowNarrowUpRight from '@kidsnote/icons/kds/ArrowNarrowUpRight';

const shortcutBannerStyle = {
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '52px',
  padding: '16px 20px',
  borderTop: '1px solid var(--N40)',
  fontSize: '14px',
  letterSpacing: '-0.28px',
};

interface Props extends FlexProps {
  text: string;
  link: string;
}

export default function ShortcutBanner({ text, link, ...props }: Props) {
  return (
    <Link href={link} passHref>
      <a target="_blank">
        <Flex
          {...shortcutBannerStyle}
          {...props}
        >
          {text}
          <IconArrowNarrowUpRight size={20} decorative />
        </Flex>
      </a>
    </Link>
  );
}
