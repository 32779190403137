import React, { HTMLAttributes } from 'react';
import { Flex, Box, HStack, BoxProps, StackProps, Center } from "@chakra-ui/react";
import GNB from "@components/common/@Layout/HomeLayoutKDS/GNB";
import LNB from "@components/common/@Layout/HomeLayoutKDS/LNB";
import Spinner from '@components/v2/Spinner';

const boxWrapper: BoxProps = {
  flex: 1,
  alignItems: 'left',
  overflow: 'hidden',
};

const GNB_HEIGHT = '60px';
const hStack: StackProps = {
  flex: 1,
  alignItems: 'top',
  height: `calc(100vh - ${GNB_HEIGHT})`,
};

const Content = {
  flex: 1,
  backgroundColor: 'white',
  overflow: 'hidden',
}

interface Props extends HTMLAttributes<HTMLDivElement>{
  isLoading?: boolean;
}
export default function HomeLayoutKDS({ isLoading, children}: Props) {
  return (
    <Box {...boxWrapper}>
      <GNB height={GNB_HEIGHT} />
      {isLoading ? 
        <Center h="100vh">
          <Spinner size="40px" />
        </Center> : 
        <HStack {...hStack} spacing={0}>
        <LNB />
        <Flex {...Content}>
          {children}
        </Flex>
        </HStack>
      }
    </Box>
  );
}
