import { Button, Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabButtonWrapper = styled(Flex)`
  padding: 4px 10px;
`;

export const TabButton = styled(Button)<Record<string, any>>(
  css`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 32px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;

    &:hover {
      background: var(--TD004);
    }
  `,

  ({ isActive }) => isActive && css`
    color: var(--G600);
    font-weight: 700;
  `,
);
