import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import client from '@apis/_axios/client';
import { AxiosError, AxiosResponse } from 'axios';
import { components, operations } from 'types/generated/dc';

export const QUERY_KEY = '/centers/{center_id}/';

export default function useCenterInfo<
  TData = components['schemas']['Center'],
>(
  {
    center_id,
  }: operations['centers_retrieve']['parameters']['path'],
  options?: UseQueryOptions<AxiosResponse, AxiosError, TData>,
) {
  return useQuery(
    [QUERY_KEY, center_id] as QueryKey,
    () => client.get(
      QUERY_KEY,
      { center_id },
    ),
    {
      enabled: !!center_id,
      useErrorBoundary: true,
      select: (response: AxiosResponse) => response.data,
      ...options,
    },
  );
}
