import { Tab as TabProps } from 'hooks/v2/useMenus';

import { ReactComponent as NotiBadge } from 'assets/icons/badge/noti_badge.svg';

import * as S from './Tab.styled';

export interface Props extends TabProps {
  isActive: boolean;
  onClick: () => void;
}

export default function Tab({
  label,
  isNew = false,
  isActive,
  onClick,
}: Props) {
  return (
    <S.TabButtonWrapper>
      <S.TabButton
        type="button"
        isActive={isActive}
        onClick={onClick}
      >
        {label}
        {isNew && <NotiBadge style={{ margin: '0' }} />}
      </S.TabButton>
    </S.TabButtonWrapper>
  );
}
