import styled from '@emotion/styled';

export const Title = styled.div`
  color: var(--N700);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.33px;
  line-height: 26.4px;
  cursor: default;
`;

export const HistoryTitle = styled(Title)`
  color: var(--N200);
  cursor: pointer;
`;
