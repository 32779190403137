import {
  AccordionButton as BaseAccordionButton,
  AccordionItem as BaseAccordionItem,
  AccordionPanel as BaseAccordionPanel,
  Box,
} from '@chakra-ui/react';

import styled from '@emotion/styled';

export const AccordionItem = styled(BaseAccordionItem)`
  display: grid;
  border: 0;
`;

export const AccordionButtonWrapper = styled(Box)`
  padding: 4px 12px;
`;

export const AccordionButton = styled(BaseAccordionButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 8px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    background-color: var(--TD004);
  }
`;

export const AccordionPanel = styled(BaseAccordionPanel)`
  padding: 12px 0;
  background-color: var(--N10);
`;
