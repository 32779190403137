import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled(Box)`
  & > svg {
    & > circle {
      stroke: var(--TD015);
    }

    & > g > circle {
      stroke: var(--G600);
    }
  }
`;
