import { Box, Flex, Text } from '@chakra-ui/react';
import IconPhone from '@kidsnote/icons/kds/Phone';

const csBannerStyle = {
  height: '70px',
  padding: '16px 20px',
  borderTop: '1px solid var(--N40)',
};

const phoneNumberContainerStyle = {
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '14px',
  fontWeight: '700',
};

const phoneNumberStyle = {
  alignItems: 'center',
  columnGap: '4px',
  fontWeight: '500',
  letterSpacing: '-0.28px',
};

const businessDayStyle = {
  marginTop: '4px',
  fontSize: '12px',
  color: 'var(--N200)',
  letterSpacing: '-0.24px',
};

export default function CsBanner() {
  return (
    <Box {...csBannerStyle}>
      <Flex {...phoneNumberContainerStyle}>
        <Flex {...phoneNumberStyle}>
          <IconPhone
            size={16}
            color="var(--N60)"
            decorative
          />
          고객센터
        </Flex>
        1644-6734
      </Flex>
      <Text {...businessDayStyle}>
        평일: 09:00 ~ 18:00 (주말/공휴일 휴무)
      </Text>
    </Box>
  );
}
