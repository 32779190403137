import { useRef, useState, useEffect } from 'react';
import { useOutsideClick, Text } from '@chakra-ui/react';
import IconChevronRight from '@kidsnote/icons/material/ChevronRight';

import useRouterPush from 'hooks/v2/useRouterPush';
import useUser from 'hooks/v2/queries/useUser';

import { components } from 'types/generated/dc';

import * as S from './CenterSelector.styled';

export default function CenterSelector() {
  const selectorContainerRef = useRef(null);
  const selectorDropdownRef = useRef(null);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState<components['schemas']['UserCenter']>();

  const { router } = useRouterPush();

  const { data: user } = useUser();

  const isActiveCenterSelector = user?.type === 'teacher' && (user?.center_set.length ?? 0) > 1;

  useOutsideClick({
    ref: selectorContainerRef,
    handler: () => setIsShowDropdown(false),
  });

  const handleChangeCenter = (center?: components['schemas']['UserCenter']) => {
    if (center) {
      setSelectedCenter(center);

      router.replace({
        pathname: router.pathname,
        query: { ...router.query, center_id: center.id },
      });
    }
  };

  useEffect(() => {
    const urlCenter = user?.center_set.find((center) => center.id === Number(router.query.center_id));
    handleChangeCenter(urlCenter ?? user?.center_set[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, router.query.center_id]);

  return (
    <S.SelectorContainer ref={selectorContainerRef}>
      {/* 셀렉터 버튼 영역 */}
      <S.SelectorButton
        type="button"
        disabled={!isActiveCenterSelector}
        onClick={() => setIsShowDropdown(!isShowDropdown)}
      >
        <Text noOfLines={1}>
          {selectedCenter?.name}
        </Text>
        
        {isActiveCenterSelector && (
          <S.SelectorButtonArrow>
            <IconChevronRight
              size={16}
              decorative
            />
          </S.SelectorButtonArrow>
        )}
      </S.SelectorButton>

      {/* 셀렉터 드롭다운 영역 */}
      <S.SelectorDropdownContainer
        ref={selectorDropdownRef}
        data-hidden={!isShowDropdown}
      >
        {user?.center_set.map((center) => (
          <S.SelectorDropdownButton
            key={center.id}
            type="button"
            onClick={() => {
              setIsShowDropdown(false);
              handleChangeCenter(center);
            }}
          >
            <Text noOfLines={1}>
              {center.name}
            </Text>
          </S.SelectorDropdownButton>
        ))}
      </S.SelectorDropdownContainer>
    </S.SelectorContainer>
  );
}
