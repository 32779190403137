import { Box, Grid, Text } from '@chakra-ui/react';

import styled from '@emotion/styled';

export const COLUMN_COUNT = 4;

export const SitemapContainer = styled(Box)`
  position: absolute;
  top: 60px;
  left: 240px;
  z-index: 10;
  width: calc(100vw - 240px);
  height: calc(100vh - 60px);
  background-color: var(--white);

  &[aria-hidden='true'] {
    display: none;
  }
`;

export const MenuContainer = styled(Grid)`
  width: 1131px;
  grid-template-columns: repeat(${COLUMN_COUNT}, 1fr);
  margin: 0 auto;
  padding: 20px 0 80px;
`;

export const MenuWrapper = styled(Box)`
  padding: 40px 20px;
  border-right: 1px solid var(--N40);
  border-bottom: 1px solid var(--N40);

  &:nth-of-type(-n + ${COLUMN_COUNT}) {
    padding-top: 20px;
  }

  &:nth-of-type(${COLUMN_COUNT}n) {
    border-right: 0;
  }

  &:nth-last-of-type(-n + ${COLUMN_COUNT}) {
    padding-bottom: 20px;
    border-bottom: 0;
  }
`;

export const MenuLabel = styled(Text)`
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.255px;
`;

export const TabButtonContainer = styled(Grid)`
  row-gap: 8px;
`;

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  height: 29px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: -0.28px;
  transition: all 0.2s;

  &::before {
    display: block;
    width: 4px;
    height: 4px;
    margin: 0 9px;
    background-color: var(--N700);
    border-radius: 50%;
    content: '';
  }

  &:hover {
    background-color: var(--TD004);
  }
`;
