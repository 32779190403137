import { AccordionIcon } from '@chakra-ui/react';

import { Menu as MenuProps } from 'hooks/v2/useMenus';
import useRouterPush from 'hooks/v2/useRouterPush';

import Tab from './Tab';

import * as S from './Menu.styled';

interface Props {
  menu: MenuProps;
  onToggleAccordion: () => void;
}

export default function Menu({ menu, onToggleAccordion }: Props) {
  const { router, routerPush } = useRouterPush();
  const { pathname, query } = router;
  const currentPathname = `/${pathname.split('/')[1]}_${query.tab}`;

  const handleChangeRouterPath = (tabIndex: number) => {
    routerPush({
      pathname: menu.path,
      query: { tab: tabIndex },
    });
  };

  return (
    <S.AccordionItem>
      <S.AccordionButtonWrapper>
        <S.AccordionButton
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            onToggleAccordion();
          }}
        >
          {menu.label}
          {!!menu.tabs?.length && <AccordionIcon />}
        </S.AccordionButton>
      </S.AccordionButtonWrapper>

      {!!menu.tabs?.length && (
        <S.AccordionPanel>
          {menu.tabs.map((tab, tabIndex) => (
            <Tab
              {...tab}
              key={tab.label}
              isActive={currentPathname === `${menu.path}_${tabIndex}`}
              onClick={() => handleChangeRouterPath(tabIndex)}
            />
          ))}
        </S.AccordionPanel>
      )}
    </S.AccordionItem>
  );
}
