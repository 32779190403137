import * as S from './Spinner.styled';
import { Spinner as BaseSpinner, SpinnerProps } from '@kidsnote/kds-ui';

export default function Spinner(props: SpinnerProps) {
  return (
    <S.Wrapper>
      <BaseSpinner
        size="32px"
        {...props}
      />
    </S.Wrapper>
  );
}
