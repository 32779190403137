import Link from 'next/link';
import { FlexProps } from '@chakra-ui/react';

import IconSetting from '@kidsnote/icons/kds/Settings01';
import IconUser from '@kidsnote/icons/kds/User02';
import IconLogout from '@kidsnote/icons/kds/LogOut01';

import useRouterPush from 'hooks/v2/useRouterPush';
import useUser from 'hooks/v2/queries/useUser';
import useCenterInfo from 'containers/OperationMGT/hooks/queries/useCenterInfo';

import CenterSelector from './CenterSelector';
import SitemapButton from './SitemapButton';

import { ReactComponent as FamilycareLogo } from 'assets/images/logo/familycare.svg';
import { ReactComponent as BetaBadge } from 'assets/images/logo/beta_badge.svg'

import * as S from './GNB.styled';

export default function GNB(props: FlexProps) {
  const { router, routerPush } = useRouterPush();
  const { data: user } = useUser();
  const { data: center } = useCenterInfo({ center_id: Number(router.query.center_id) });

  return (
    <S.GnbContainer {...props}>
      {/* 로고 */}
      <Link href={`/?center_id=${router?.query?.center_id ?? ''}`}>
        <S.LogoWrapper>
          <FamilycareLogo
            width={131}
            fill="var(--white)"
          />
          {center?.kind === 'day_night_care' && <BetaBadge width={46} />}
        </S.LogoWrapper>
      </Link>

      <S.UtilContainer>
        {/* 센터명 */}
        <CenterSelector />

        {/* 설정 */}
        {user?.type === 'admin' && (
          <button
            type="button"
            onClick={() => routerPush({ pathname: '/operation_mgt', query: { tab: 0 } })}
          >
            <S.UtilButtonContainer>
              <IconSetting
                size={20}
                accessibilityLabel='setting'
              />
              설정
            </S.UtilButtonContainer>
          </button>
        )}

        {/* 내 정보 */}
        <Link href={`${process.env.NEXT_PUBLIC_FN_WEB_MAIN_HOST}/account/settings`}>
          <S.UtilButtonContainer>
            <IconUser
              size={20}
              accessibilityLabel='my-info'
            />
            내 정보
          </S.UtilButtonContainer>
        </Link>

        {/* 로그아웃 버튼 */}
        <Link href={`${process.env.NEXT_PUBLIC_FN_WEB_MAIN_HOST}/logout?next=${encodeURIComponent(`${process.env.NEXT_PUBLIC_FN_WEB_MAIN_HOST}/login`)}`}>
          <S.UtilButtonContainer>
            <IconLogout
              size={20}
              accessibilityLabel='logout'
            />
            로그아웃
          </S.UtilButtonContainer>
        </Link>

        {/* 사이트맵 버튼 */}
        <SitemapButton />
      </S.UtilContainer>
    </S.GnbContainer>
  );
}
