import { useMemo } from 'react';

import useUser from '../queries/useUser'
import useRouterPush from '../useRouterPush';

import { useEmployeePermissionMeRetrieveQuery } from '@apis/EmployeePermission';
import { EmployeePermissionType } from '@apis/@types/data-contracts';

// import baseMenus from './menus'; > 외주사의 경우 32L의 baseMenus를 사용하지 않고 이 baseMenus를 사용한다
import dcMenus from './dcMenus';
import hcMenus from './hcMenus';
import { Menus } from './types';

export default function useMenus() {
  const { data: user } = useUser();
  const { router } = useRouterPush();

  const center = user?.center_set.find((center) => center.id === Number(router.query.center_id ?? 0));

  const { data: employeePermissionData } = useEmployeePermissionMeRetrieveQuery(
    {
      variables: { centerId: Number(router.query.center_id) },
      options: { enabled: !!router.query.center_id  && !!center && center.kind !== 'day_night_care' },
    },
  );

  const menus: Menus = useMemo(() => {
    if (!user || !center) {
      return {};
    }

    const baseMenus = center.kind === 'day_night_care' ? dcMenus : hcMenus;

    const filteredMenus = Object.entries(baseMenus)
      // 1. 메뉴의 allowed로 필터링
      .filter(([, menu]) => {
        return menu.allowed.userType.includes(user.type); // && menu.allowed.centerType.includes(center.kind); > 9L의 baseMenus를 사용할 때 필요한 코드
      })
      // 2. 탭의 allowed로 필터링
      .map(([key, menu]) => {
        const newTabs = menu.tabs.filter((tab) => {
          const allowedUserType = tab.allowed?.userType ?? menu.allowed.userType;
          // const allowedCenterType = tab.allowed?.centerType ?? menu.allowed.centerType; > 9L의 baseMenus를 사용할 때 필요한 코드
          return allowedUserType.includes(user.type); // && allowedCenterType.includes(center.kind); > 9L의 baseMenus를 사용할 때 필요한 코드
        });

        return [
          key,
          { ...menu, tabs: newTabs }
        ];
      });

      const isKeyOfEmployeePermissionType = (key: string, data: EmployeePermissionType): key is keyof EmployeePermissionType => {
        return key in data;
      }
      
      const convertMenuKeyToPermissionKey = (menuKey: string): string => {
        switch (menuKey) {
          case 'payee_mgt':
            return 'Recipient';
          case 'schedule_mgt':
            return 'Schedule';
          case 'salary_supply':
            return 'ServiceProvision';
          case 'staff_mgt':
            return 'Employee';
          case 'charge':
            return 'BurdenPrice';
          case 'service':
            return 'ExtraService';
          default:
            return '';
        }
      }

      if (center.kind === 'home_visit_care' && user.type !== 'admin' && employeePermissionData) {
        const remainingMenu: Menus = Object.fromEntries(filteredMenus);
        let activeTabs: Menus = {};

        for (const menuKey in remainingMenu) {
          const menu = remainingMenu[menuKey];
          activeTabs[menuKey] = {
            ...menu,
            tabs: menu.tabs.filter((_, index) => {
              const permissionKey = `is${convertMenuKeyToPermissionKey(menuKey)}${index + 1}`;
              if (isKeyOfEmployeePermissionType(permissionKey, employeePermissionData)) {
                return employeePermissionData[permissionKey];
              }
            }),
          };
        }

        const permissionCategories = [
          { key: 'payee_mgt', start: 0, end: 6 },
          { key: 'schedule_mgt', start: 6, end: 14 },
          { key: 'salary_supply', start: 14, end: 16 },
          { key: 'staff_mgt', start: 16, end: 22 },
          { key: 'charge', start: 22, end: 25 },
          { key: 'service', start: 25, end: 28 },
        ];

        for (const category of permissionCategories) {
          const isAllFalse = Object.values(employeePermissionData)
            .slice(category.start, category.end)
            .every((value) => value === false);
          
          if(isAllFalse) {
            const {[category.key]:removedKey, ...rest} = activeTabs
            activeTabs = rest
          }
        }

        return activeTabs;
      } else {
        return Object.fromEntries(filteredMenus);
      }
      
  }, [center, user, employeePermissionData]);

  return menus;
}
